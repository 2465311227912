<template>
	<Transition name="fade">
		<div
			class="notification"
			:class="`notification--${mode}-${type}`"
		>
			<ZyroSvg
				:name="icon"
				class="notification__icon"
			/>
			<div class="notification__wrapper">
				<p class="notification__message z-body-small">
					{{ message }}
				</p>
			</div>
		</div>
	</Transition>
</template>

<script>

export default {
	props: {
		type: {
			type: String,
			validator(value) {
				return [
					'error',
					'success',
				].includes(value);
			},
			default: 'success',
		},
		message: {
			type: String,
			required: true,
		},
		mode: {
			type: String,
			validator(value) {
				return [
					'light',
					'dark',
				].includes(value);
			},
			default: 'light',
		},
		icon: {
			type: String,
			default: 'info-circle',
		},
	},
};
</script>

<style lang="scss" scoped>
.notification {
	display: flex;
	padding: 18px;
	margin-top: 32px;
	margin-bottom: 24px;
	border-radius: 8px;

	@include mobile-view {
		margin-bottom: 16px;
	}

	$styles:
		"light" "error" $secondary,
		"light" "success" $accent-two,
		"dark" "error" $light $secondary,
		"dark" "success" $light $accent-two;

	@each $mode, $type, $color, $background in $styles {
		&--#{$mode}-#{$type} {
			color: $color;
			background: rgba($color, 0.1);

			@if $background {
				background: rgba($background, 0.6);
			}

			svg {
				color: $color;
			}
		}
	}

	&__wrapper {
		display: flex;
		align-items: center;
		text-align: left;
	}

	&__message {
		margin-bottom: 0;
	}

	&__icon {
		min-width: 20px;
		min-height: 20px;
		margin-right: 8px;
	}
}

</style>
