<template>
	<div class="parking-instructions">
		<ZyroNotification
			class="parking-instructions__notification"
			type="error"
			:message="$t('siteSettings.domain.hasDomain.incorrectNS')"
		/>
		<InfoBanner
			:title="$t('siteSettings.domain.hasDomain.errorBannerTitle')"
			icon-name="info-circle"
		>
			<ul>
				<li class="banner-item z-body-small">
					{{ $t('siteSettings.domain.hasDomain.errorBannerFirstItem') }}
				</li>
				<li
					v-if="nameserversList.length"
					class="banner-item z-body-small"
				>
					{{ $t('siteSettings.domain.hasDomain.errorBannerSecondItem') }}
				</li>
				<i18n
					v-else
					path="siteSettings.domain.hasDomain.errorBannerSecondItemWithLink"
					tag="li"
					class="banner-item z-body-small"
				>
					<a
						class="z-link"
						:href="NS_INSTRUCTIONS_URL"
						target="_blank"
						:title="$t('siteSettings.domain.instructionsTitle')"
					>
						{{ $t('siteSettings.domain.hasDomain.theseInstructions') }}
					</a>
				</i18n>
			</ul>
			<div
				v-if="nameserversList.length"
				class="parking-instructions__ns-container"
			>
				<div class="parking-instructions__ns-container-inner">
					<h6 class="z-body-small z-body-small--strong parking-instructions__ns-container-item">
						{{ $t('siteSettings.domain.hasDomain.currentNsSetup') }}
					</h6>
					<ul class="parking-instructions__ns-container-list">
						<li
							v-for="(record, index) in nameserversList"
							:key="`${record}-${index}`"
							class="z-body-small parking-instructions__ns-container-item"
						>
							{{ record }}
						</li>
					</ul>
				</div>
				<div class="parking-instructions__ns-container-inner">
					<h6 class="z-body-small z-body-small--strong parking-instructions__ns-container-item">
						{{ $t('siteSettings.domain.hasDomain.correctNsSetup') }}
					</h6>
					<ul class="parking-instructions__ns-container-list">
						<li
							v-for="(record, index) in $options.CORRECT_NAMESERVERS_LIST"
							:key="`${record}-${index}`"
							class="z-body-small parking-instructions__ns-container-item"
						>
							{{ record }}
						</li>
					</ul>
				</div>
			</div>
			<template slot="button">
				<ZyroButton
					theme="outline"
					color="black"
					data-qa="sitesettings-domain-btn-verifyconnection"
					@click="$emit('verify-connection')"
				>
					{{ $t('siteSettings.domain.verifyConnection') }}
				</ZyroButton>
			</template>
		</InfoBanner>
	</div>
</template>

<script>
import ZyroNotification from '@/components/site-settings/components/ZyroNotification.vue';
import InfoBanner from '@/components/site-settings/pages/domain/domain-connection/-partials/InfoBanner.vue';
import {
	CORRECT_NAMESERVERS_LIST,
	NS_INSTRUCTIONS_URL,
} from '@/components/site-settings/pages/domain/domain-connection/constants';

export default {
	CORRECT_NAMESERVERS_LIST,
	components: {
		ZyroNotification,
		InfoBanner,
	},
	props: {
		nameserversList: {
			type: Array,
			default: () => [],
		},
	},
	setup() {
		return { NS_INSTRUCTIONS_URL };
	},
};
</script>

<style lang="scss" scoped>
.parking-instructions {
	&__notification {
		margin-bottom: 32px;
	}

	&__ns-container {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		margin-top: 16px;

		&:first-child {
			margin-right: 24px;
		}
	}

	&__ns-container-inner {
		display: flex;
		flex-direction: column;
	}

	&__ns-container-list {
		list-style: none;
	}

	&__ns-container-item {
		margin-bottom: 8px;
	}
}

.banner-item {
	position: relative;
	margin-top: 16px;
	margin-left: 15px;
	list-style-type: none;

	&::before {
		position: absolute;
		left: -0.8em;
		font-size: 1.1em;
		content: "•";
	}
}
</style>
