<template>
	<Transition
		name="slide-right"
		mode="out-in"
	>
		<DomainLoader v-if="isLoading" />
		<Component
			:is="currentStepComponent"
			v-else
		/>
	</Transition>
</template>

<script>
import { mapState } from 'vuex';

import DomainLoader from '@/components/site-settings/pages/domain/domain-connection/-partials/DomainLoader.vue';
import {
	DOMAIN_CARD_STEP,
	HAS_DOMAIN_STEP,
} from '@/components/site-settings/pages/domain/domain-connection/constants';
import DomainCardStep from '@/components/site-settings/pages/domain/domain-connection/steps/DomainCardStep.vue';
import HasDomain from '@/components/site-settings/pages/domain/domain-connection/steps/HasDomain.vue';

import { useDomainConnection } from './useDomainConnection';

export default {
	components: {
		DomainCardStep,
		HasDomain,
		DomainLoader,
	},
	setup() {
		const {
			setCurrentStepComponent,
			currentStepComponent,
			isDomainConnected,
			getProviderInformation,
			verifyConnection,
		} = useDomainConnection();

		return {
			setCurrentStepComponent,
			currentStepComponent,
			verifyConnection,
			isDomainConnected,
			getProviderInformation,
		};
	},
	data() {
		return { isLoading: false };
	},
	computed: mapState(['customDomain']),
	async beforeMount() {
		if (!this.customDomain) {
			this.setCurrentStepComponent(DOMAIN_CARD_STEP);

			return;
		}

		this.isLoading = true;
		await this.verifyConnection();
		if (!this.isDomainConnected) {
			await this.getProviderInformation(this.customDomain);
		}

		this.setCurrentStepComponent(HAS_DOMAIN_STEP);
		this.isLoading = false;
	},
};
</script>
