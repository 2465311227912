<template>
	<div class="card">
		<ZyroCollapsible
			:is-open="isOpen"
			:is-content-with-top-padding="false"
			data-qa="sitesettings-domain-card-connecteddomain"
			@toggle="isOpen = !isOpen"
		>
			<template #heading>
				<DomainCard
					:domain="customDomain"
					:is-button-shown="false"
				>
					<template #status-pill>
						<ZyroPill
							v-qa="`sitesettings-domain-pill-${pillText}`"
							:text="pillText"
							:pill-text-color="pillTextColor"
							:pill-background-color="pillBackgroundColor"
							:pill-vertical-padding="'0'"
							text-class="z-body-small z-body-small--strong"
						/>
					</template>
				</DomainCard>
			</template>
			<template
				#content
				class="card__content"
			>
				<div class="card__content">
					<Transition
						name="slide-left"
						mode="out-in"
					>
						<DomainLoader v-if="isBeingVerified" />
						<template v-else>
							<ParkingInstructions
								v-if="areInstructionsShown"
								class="parking-instructions"
								:nameservers-list="currentNameservers"
								@verify-connection="handleVerifyConnection"
							/>
							<InfoBanner
								v-if="!hasVerificationTimedOut && !isDomainConnected"
								class="verify-banner"
								:title="$t('siteSettings.domain.hasDomain.giveItSomeTime')"
								data-testId="verify-banner"
							>
								<p class="z-body z-body-small z-body--spaced">
									{{ $t('siteSettings.domain.hasDomain.verificationModalStepSubtitle') }}
								</p>
								<template slot="button">
									<ZyroButton
										theme="outline"
										color="black"
										data-qa="sitesettings-domain-btn-verifyconnection"
										@click="handleVerifyConnection"
									>
										{{ $t('siteSettings.domain.verifyConnection') }}
									</ZyroButton>
								</template>
							</InfoBanner>
						</template>
					</Transition>
					<ZyroButton
						theme="plain"
						color="red"
						class="card__remove-button"
						data-qa="sitesettings-domain-btn-disconnectdomain"
						:title="$t('siteSettings.domain.disconnectDomain')"
						@click="activeModalName = REMOVE_DOMAIN_MODAL"
					>
						{{ $t('siteSettings.domain.disconnectDomain') }}
					</ZyroButton>
				</div>
			</template>
		</ZyroCollapsible>
		<Portal to="app">
			<SiteSettingsModal
				v-if="activeModalName === REMOVE_DOMAIN_MODAL"
				:title="$t('siteSettings.domain.disconnectDomain')"
				:right-button-text="$t('siteSettings.domain.disconnectDomain')"
				right-button-color="red"
				@close="activeModalName = ''"
				@left-button-click="activeModalName = ''"
				@right-button-click="handleRemoveClick"
			>
				<i18n
					path="siteSettings.domain.hasDomain.removeModalText"
					tag="p"
					class="z-body z-body--spaced"
				>
					<span class="z-font-weight-bold">{{ customDomain }}</span>
				</i18n>
			</SiteSettingsModal>
		</Portal>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import SiteSettingsModal from '@/components/site-settings/components/SiteSettingsModal.vue';
import ZyroCollapsible from '@/components/site-settings/components/ZyroCollapsible.vue';
import DomainCard from '@/components/site-settings/pages/domain/domain-connection/-partials/DomainCard.vue';
import DomainLoader from '@/components/site-settings/pages/domain/domain-connection/-partials/DomainLoader.vue';
import InfoBanner from '@/components/site-settings/pages/domain/domain-connection/-partials/InfoBanner.vue';
import ParkingInstructions from '@/components/site-settings/pages/domain/domain-connection/-partials/ParkingInstructions.vue';
import {
	DOMAIN_CARD_STEP,
	REMOVE_DOMAIN_MODAL,
} from '@/components/site-settings/pages/domain/domain-connection/constants';
import { useDomainConnection } from '@/components/site-settings/pages/domain/domain-connection/useDomainConnection';

export default {
	components: {
		ZyroCollapsible,
		InfoBanner,
		ParkingInstructions,
		SiteSettingsModal,
		DomainCard,
		DomainLoader,
	},
	setup() {
		const {
			isBeingVerified,
			isDomainConnected,
			hasVerificationTimedOut,
			verifyConnection,
			hasFailed,
			setCurrentStepComponent,
			providerData,
			removeDomain,
			currentNameservers,
		} = useDomainConnection();

		return {
			isBeingVerified,
			isDomainConnected,
			hasVerificationTimedOut,
			verifyConnection,
			hasFailed,
			setCurrentStepComponent,
			providerData,
			removeDomain,
			currentNameservers,
			REMOVE_DOMAIN_MODAL,
		};
	},
	data() {
		return {
			activeModalName: '',
			isOpen: false,
		};
	},
	computed: {
		...mapState(['customDomain']),
		areInstructionsShown() {
			return !this.isDomainConnected && this.hasVerificationTimedOut;
		},
		pillBackgroundColor() {
			if (this.isDomainConnected) {
				return 'var(--success)';
			}

			return this.hasVerificationTimedOut ? 'var(--secondary)' : 'var(--accent-three)';
		},
		pillTextColor() {
			if (this.isDomainConnected) {
				return 'var(--light)';
			}

			return this.hasVerificationTimedOut ? 'var(--light)' : 'var(--dark)';
		},
		pillText() {
			if (this.isDomainConnected) {
				return this.$t('common.completed');
			}

			return this.hasVerificationTimedOut ? this.$t('siteSettings.domain.connectionFailed') : this.$t('siteSettings.domain.waitingForConnection');
		},
	},
	methods: {
		async handleVerifyConnection() {
			await this.verifyConnection(true);
			if (this.hasFailed) {
				return;
			}

			EventLogApi.logEvent({ eventName: 'site_settings.custom_domain.verify_connection' });
		},
		async handleRemoveClick() {
			await this.removeDomain();
			if (this.hasFailed) {
				this.activeModalName = '';

				return;
			}

			EventLogApi.logEvent({ eventName: 'site_settings.custom_domain.remove_domain' });
			this.setCurrentStepComponent(DOMAIN_CARD_STEP);
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
	&__content {
		padding-left: 56px;
		margin-top: -16px;

		@include mobile-view {
			padding-left: 0;
			margin-top: 0;
		}
	}

	&__remove-button {
		margin-top: 8px;
	}
}

.parking-instructions {
	margin-bottom: 32px;
}

.verify-banner {
	margin-top: 24px;

	@include mobile-view {
		margin-top: 40px;
	}
}
</style>
