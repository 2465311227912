<template>
	<section class="domain">
		<ZyroSettingsTitle>
			{{ $t('siteSettings.nav.domain') }}
		</ZyroSettingsTitle>
		<div class="domain__container">
			<div class="domain__text">
				<ZyroText
					v-for="({ title, text }, index) in $t('siteSettings.domain.general.infoTexts')"
					:key="`${index}-infotext`"
				>
					<template #title>
						<p class="domain__text-title">
							{{ title }}
						</p>
					</template>
					<template #text>
						{{ text }}
					</template>
				</ZyroText>
			</div>
			<div class="domain__conection">
				<DomainConnection />
				<BannerDomain
					v-if="!customDomain"
					:domain-redirect-link="domainRedirectLink"
				/>
			</div>
		</div>
	</section>
</template>

<script>
import { mapState } from 'vuex';

import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import ZyroText from '@/components/site-settings/components/ZyroText.vue';
import BannerDomain from '@/components/site-settings/pages/domain/domain-connection/-partials/BannerDomain.vue';
import DomainConnection from '@/components/site-settings/pages/domain/domain-connection/DomainConnection.vue';
import { WWW_REDIRECT_PATHS } from '@/constants';
import { useRedirects } from '@/use/useRedirects';

const { GET_A_DOMAIN } = WWW_REDIRECT_PATHS;

export default {
	components: {
		ZyroSettingsTitle,
		ZyroText,
		BannerDomain,
		DomainConnection,
	},
	setup() {
		const { getRedirectToWWWUrl } = useRedirects();

		return { getRedirectToWWWUrl };
	},
	computed: {
		...mapState(['customDomain']),
		domainRedirectLink: ({ getRedirectToWWWUrl }) => getRedirectToWWWUrl({ path: GET_A_DOMAIN }),
	},
};
</script>

<style lang="scss" scoped>
.domain {
	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@include mobile-view {
			flex-direction: column;
		}
	}

	&__text {
		width: 35%;
		margin-right: 40px;

		@include mobile-view {
			order: 2;
			width: 100%;
			margin-right: 0;
		}
	}

	&__text-title {
		margin-bottom: 8px;
	}

	&__conection {
		width: 65%;

		@include mobile-view {
			order: 1;
			width: 100%;
			margin-bottom: 32px;
		}
	}
}
</style>
