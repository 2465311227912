<template>
	<div>
		<ZyroCard>
			<DomainCard
				:domain="domain"
				@connect-domain="handleNextStepClick"
			/>
		</ZyroCard>
		<SiteSettingsModal
			v-if="showModal"
			:title="$t('siteSettings.common.doubleCheck')"
			:right-button-text="$t('common.choosePlan')"
			@close="showModal = false"
			@left-button-click="showModal = false"
			@right-button-click="$router.push({ name: SUBSCRIPTION_SETTINGS_ROUTE })"
		>
			{{ $t('siteSettings.domain.domainCard.upgradeText') }}
		</SiteSettingsModal>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import SiteSettingsModal from '@/components/site-settings/components/SiteSettingsModal.vue';
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import DomainCard from '@/components/site-settings/pages/domain/domain-connection/-partials/DomainCard.vue';
import { ENTER_DOMAIN_CARD_STEP } from '@/components/site-settings/pages/domain/domain-connection/constants';
import { useDomainConnection } from '@/components/site-settings/pages/domain/domain-connection/useDomainConnection';
import { SUBSCRIPTION_SETTINGS_ROUTE } from '@/router';
import { DOMAIN_CONNECTION_MODAL } from '@/store/builder/constants/modals';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';

export default {
	components: {
		DomainCard,
		ZyroCard,
		SiteSettingsModal,
	},
	setup() {
		const { setCurrentModalStepComponent } = useDomainConnection();

		return {
			setCurrentModalStepComponent,
			SUBSCRIPTION_SETTINGS_ROUTE,
		};
	},
	data() {
		return { showModal: false };
	},
	computed: {
		...mapState([
			'hasActivePlan',
			'domain',
		]),
		...mapState('user', ['user']),
	},
	methods: {
		...mapActionsGui({ openModal: OPEN_MODAL }),
		...mapActions('subscription', ['autoConnectPlan']),
		async handleNextStepClick() {
			await this.autoConnectPlan();

			if (!this.hasActivePlan) {
				this.showModal = true;

				return;
			}

			EventLogApi.logEvent({ eventName: 'site_settings.custom_domain.start' });
			if (this.user?.id) {
				window.hj('identify', this.user.id, { 'site_settings.custom_domain.start': true });
			}

			this.setCurrentModalStepComponent(ENTER_DOMAIN_CARD_STEP);
			this.openModal({ name: DOMAIN_CONNECTION_MODAL });
		},
	},
};
</script>
