<template>
	<div class="domain-card">
		<div class="domain-card__subtitle">
			<div class="z-subheading-small z-subheading-small--spaced domain-card__subtitle-text">
				{{ $t('siteSettings.domain.domainCard.websiteDomain') }}
			</div>
			<div
				v-if="$slots['status-pill']"
				class="domain-card__pill"
			>
				<slot name="status-pill" />
			</div>
		</div>
		<div class="domain-card__domain">
			<p class="z-body z-body--spaced z-body--strong domain-card__domain-text">
				{{ domain || $t('siteSettings.domain.domainCard.noDomain') }}
			</p>
			<ZyroSvg
				name="globe-americas"
				class="domain-card__icon"
			/>
		</div>
		<ZyroButton
			v-if="isButtonShown"
			theme="outline"
			class="domain-card__button"
			data-qa="sitesettings-domain-btn-connectcustomdomain"
			@click="$emit('connect-domain')"
		>
			{{ $t('siteSettings.domain.connectCustomDomain') }}
		</ZyroButton>
	</div>
</template>

<script>
export default {
	props: {
		domain: {
			type: String,
			default: '',
		},
		isButtonShown: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>

.domain-card {
	padding-left: 56px;

	@include mobile-view {
		padding-left: 0;
	}

	&__subtitle {
		display: flex;

		@include mobile-view {
			flex-wrap: wrap-reverse;
		}
	}

	&__subtitle-text {
		margin-right: 16px;
	}

	&__domain {
		position: relative;
		word-break: break-all;

		@include mobile-view {
			padding-left: 24px;
		}
	}

	&__domain-text {
		@include mobile-view {
			margin-left: 10px;
		}
	}

	&__icon {
		position: absolute;
		top: -14px;
		left: -48px;

		@include mobile-view {
			top: 3px;
			left: 0;
		}
	}

	&__pill {
		@include mobile-view {
			margin-top: 1px;
			margin-bottom: 8px;
		}
	}

	&__button {
		margin-top: 20px;

		@include mobile-view {
			margin-top: 16px;
			margin-bottom: 22px;
		}
	}
}
</style>
