<template>
	<ZyroCard class="banner">
		<div class="banner__container">
			<img
				src="@/assets/images/banner-domain.png"
				:alt="$t('siteSettings.domain.general.bannerImageAlt')"
				class="banner__img"
			>
			<div class="banner__content">
				<h3 class="z-h5 z-font-weight-light banner__content-title">
					{{ $t('siteSettings.domain.general.bannerTitle') }}
				</h3>
				<p class="z-body">
					{{ $t('siteSettings.domain.general.bannerText') }}
				</p>
			</div>
			<ZyroButton
				theme="primary"
				color="red"
				data-qa="sitesettings-domain-btn-buynewdomain"
				:href="domainRedirectLink"
			>
				{{ $t('siteSettings.domain.general.bannerButtonText') }}
			</ZyroButton>
		</div>
	</ZyroCard>
</template>

<script>
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';

export default {
	components: { ZyroCard },
	props: {
		domainRedirectLink: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.banner {
	position: relative;
	z-index: 2;
	padding: 0 72px 48px 88px;

	@include mobile-view {
		padding: 0 16px 32px 16px;
	}

	&__content {
		margin-bottom: 40px;

		@include mobile-view {
			margin-bottom: 24px;
		}
	}

	&__content-title {
		margin-bottom: 8px;
	}

	&__img {
		width: 100%;
		margin: -5px 0 24px -30px;

		@include mobile-view {
			margin-left: 0;
		}
	}
}

</style>
